.PopUp .DetailsUser{
    width: 500px;
    margin: 2% auto;
}
.PopUp .DetailsUser .title,
.PopUp .DetailsUser .close{
    border: none;
}
.PopUp .DetailsUser .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .DetailsUser .div_input{
    width: auto;
}

.PopUp .DetailsUser .div_inf_user{
    margin-top: 20px;
    width: 80%;
}
.PopUp .DetailsUser .div_inf_user .div_input{
    
}
.PopUp .DetailsUser .div_inf_user .div_input:nth-child(1n){
    margin-top: 16px;
}

.PopUp .DetailsUser .div_img_user{
    width: 100px;
    height: 100px;
}
.PopUp .DetailsUser .div_img_user .icons{
    width: 100%;
    height: 100%;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PopUp .DetailsUser .btn_alt_status{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.PopUp .DetailsUser .btn_alt_status .div_icons{
    border-radius: 20px;
    width: 26px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.PopUp .DetailsUser .btn_alt_status .div_icons .icons_ {
    width: 20px;
    height: auto;
}
.PopUp .DetailsUser .btn_alt_status .icons_1 {
    width: 30px;
    height: 30px;
}
.PopUp .DetailsUser .btn_alt_status .icons_1 .icons {
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 570px) {
    .PopUp .DetailsUser {
        width: 90%;
    }
}
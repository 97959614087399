.new_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}
.new_block .div_return{
    cursor: pointer;
    font-weight: 600;
    color: #20489e;
}
.new_block .div_search{
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.new_block .div_search .search{
    width: 260px;
    padding: 10px 40px 10px 10px;
}
.new_block .div_search .icons{
    position: absolute;
    top: 8px;
    right: 10px;
    transition: 0.5s;
}
.new_block .title_page{
    font-size: 24px;
    font-weight: 600;
    flex-grow: 1;
    text-align: center;
    text-transform: uppercase;
}
.new_block .list_opt_topic{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.new_block .list_opt_topic .div_new_data{
    position: relative;
    width: auto;
    min-width: 170px;
    text-align: center;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    padding: 0;
    font-size: 14px;
    background-color: #0089D0;
    border-radius: 6px;
    font-family: 'Outfit';
}
.new_block .list_opt_topic .div_new_data .new_data_add{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    background-color: #20489e;
    border-radius: 6px;
}
.new_block .list_opt_topic .div_new_data .name_btn_add{
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
}
.new_block .list_opt_topic .div_new_data:hover .new_data_add{
    width: 100%;
}
.new_block .list_opt_topic .div_new_data:hover .name_btn_add{
    width: 0;
    padding-left: 0px;
}
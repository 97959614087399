.PopUp .photo_crop{
    width: 600px;
}
.PopUp .photo_crop .image{
    width: 100%;
    max-height: 470px;
}
.PopUp .photo_crop .type_crop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}
.PopUp .photo_crop .type_crop .crop_img{
    padding: 10px;
    border: 1px solid #2D3C53;
    border-radius: 6px;
    cursor: pointer;
}
.PopUp .photo_crop .type_crop .crop_img_active{
    background-color: #2D3C53;
    color: #FFFFFF;
}
.PopUp .photo_crop .btn_save{
    background-color: #20489e;
    color: #ffffff;
    border-radius: 6px;
    padding: 6px 20px;
    cursor: pointer;
}
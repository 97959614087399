html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Outfit';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.icons_cover{
    width: 24px;
    height: auto;
    display: block;
    cursor: pointer;
}
.cursor_pointer{
    cursor: pointer;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
    margin: 0px;
    padding: 0px;
}
ul li {
    margin-bottom: 20px;
}
.space_top{
    margin-top: 20px;
}
.space_top_not{
    margin: 0px !important;
}
.space_div {
    flex-grow: 1;
}
.no_data {
    font-size: 12px;
    width: 100%;
    text-align: center;
}
.width_page{
    width: 100%;
    max-width: 1220px;
    height: auto;
    margin-bottom: 50px;
    color: #626262;
}

/* input */
input, select, textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
    font-family: 'Outfit';
}
input[type="file"] {
    display: none;
}
select{
    padding: 9px;
}
::placeholder{
    color: rgba(140, 140, 141, 0.5);
}
.div_btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.btn_save{
    background-color: #20489e;
    color: #ffffff;
    border-radius: 6px;
    padding: 8px 20px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    font-family: 'Outfit';
    white-space: nowrap;
}
.div_search .search {
    background-color: #cdd1d6;
    border: 2px solid #cdd1d6;
    border-radius: 8px;
    padding: 10px 10px 10px 30px;
    width: 200px;
}
.div_search ::placeholder {
    color: #b0b0b0;
}
/* end */


/* list pages */
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}
.list_pages .numb_page{
    background-color: #111827;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.list_pages .numb_page:hover{
    background-color: #4a566e;
}
.list_pages .page_active {
    background-color: #a9b9cb;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #5f7196;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #20489E;
    border-radius: 6px;
}
/* end */


/* box text */
.jodit-react-container{
    width: -webkit-fill-available;
    text-align: left;
}
.jodit-wysiwyg p{
    padding: 0 !important;
    margin: 0 !important;
    font-family: "inter";
}
.jodit-status-bar{
    display: none;
}
.jodit-add-new-line, .jodit-add-new-line_after, .jodit-dialog__header-toolbar{
    display: none !important;
}
.jodit-dialog__panel{
    width: 380px !important;
}
.jodit-ui-button_keep, .jodit-ui-button_insert_as_text, .jodit-ui-button_text-icons_true, .jodit-status-bar{
    display: none !important;
}
.jodit-toolbar__box{
    border-bottom: none !important;
}
.jodit-container:not(.jodit_inline){
    border-color: #EEF1F5 !important;
}
.jodit-ui-button_insert_only_text{
    background-color: #7c9f06 !important;
    color: #FFFFFF !important;
    display: block !important;
}
.jodit-ui-button_insert_only_text > span{
    display: none !important;
}
.jodit-ui-button_insert_only_text::after{
    content: "Copiar somente o texto!";
}
.jodit-dialog__content{
    display: none !important;
}
.jodit-dialog_footer_true .jodit-dialog__footer{
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt{
    width: -webkit-fill-available;
    text-align: center;
    max-width: 100% !important;
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt > label{
    display: none !important;
}
.jodit-wysiwyg p img{
    vertical-align: middle;
}
.type_free{
    background-color: #9eb7d3;
    padding: 6px 10px;
    width: 60px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.type_free_active{
    background-color: #324d6b;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    background-color: #E2E8F0;
    padding: 16px;
}
table tr{
    background-color: #ffffff;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 16px;
    font-size: 12px;
}
table th:nth-child(-n + 1){
    border-top-left-radius: 8px;
}
table th:nth-last-child(-n + 1){
    border-top-right-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-child(-n + 1){
    border-bottom-left-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-last-child(-n + 1){
    border-bottom-right-radius: 8px;
}
.list_opt{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
}
.PopUpCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    margin-bottom: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    height: fit-content;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
}
.PopUp .div_input{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
}
.PopUp .div_input .name_input{
    position: absolute;
    top: -10px;
    left: 10px;
    background: #ffffff;
    padding: 0px 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .register_data{
    background-color: #7c9f06;
    width: calc(100% - 20px);
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 16px;
}
.PopUp .opt_select{
    cursor: pointer;
    width: 120px;
    color: #ffffff;
}
.PopUp .yes_update {
    background-color: #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .not_update {
    background-color: #e95656;
    padding: 6px 10px;
    border-radius: 8px;
}
/* end */


/* input file */
.new_file_add{
    background-color: #0089d0;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
}
.new_file_add:hover, .new_file_add_active{
    background-color: #20489e;
}
.div_add_img{
    display: flex !important;
    align-items: center !important;
    gap: 3px !important;
}
.div_add_img .delete_img_add{
    position: relative;
    margin-left: -10px;
    background-color: #e6272c;
    padding: 7px;
    color: #FFFFFF;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.new_file_add label{
    cursor: pointer;
}
.new_file_add input[type="file"]{
    display: none;
}
/* end */


/* show data */
.show_page_data{
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.show_page_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .list_type_data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 10px;
    margin-bottom: 16px;
}
.show_page_data .list_type_data .type_title{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;
}
.show_page_data .list_input_data{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.show_page_data .list_input_data:nth-child(1n){
    margin-top: 16px;
}
.show_page_data .list_input_data:nth-last-child(-n + 1){
    margin-bottom: 10px;
}
.show_page_data .div_line_top:nth-child(1n){
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 20px;
}
.show_page_data .div_line_top:nth-last-child(-n + 1){
    border-bottom: none;
    padding-bottom: 0px;
}
.show_page_data .list_input_data .div_input {
    position: relative;
    margin-top: 10px;
}
.show_page_data .list_input_data .div_input .name_input{
    position: absolute;
    left: 10px;
    padding: 0px 4px;
    background-color: #ffffff;
    top: -10px;
    font-style: italic;
}

.show_page_data .list_input_data .show_img{
    width: 146px;
    height: 146px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.show_page_data .list_input_data .show_img .delete{
    position: absolute;
    background: #ffffff;
    border-radius: 50%;
    padding: 6px;
    bottom: 5px;
    right: 5px;
    z-index: 10;
}
.show_page_data .list_input_data .show_img .delete .icons{
    width: 16px;
    height: auto;
}
.show_page_data .list_input_data .show_img .img_{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.show_page_data .list_input_data .add_img {
    width: 142px;
    height: 142px;
    border: 2px dashed #b0b0b0;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* response */
@media only screen and (max-width: 500px) {
    input, select, textarea{
        font-size: 12px;
        width: calc(100% - 24px);
    }
    .jodit-container:not(.jodit_inline) .jodit-wysiwyg{
        font-size: 12px;
    }
    .div_add_new_data{
        min-width: 100px;
    }
    .new_file_add{
        width: 100%;
    }
    table th, table td{
        padding: 10px 6px;
    }
}
/* end */
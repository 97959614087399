.PopUp .return_response{
    width: 400px;
}
.PopUp .return_response .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .return_response .title_return{
    font-size: 16px;
}
.PopUp .return_response .close_return{
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 6px;
}
@media only screen and (max-width: 480px) {
    .PopUp .return_response {
        width: 80%;
    }
}
.Contents{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    color: #ffffff;
    font-weight: 400;
}

.Contents .div_menu{
    width: 220px;
    height: 100%;
    background: #20489E;
    overflow-y: auto;
}
.Contents .div_menu .list_menu{

}
.Contents .div_menu .div_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ffffff;
}
.Contents .div_menu .div_logo .logo{
    width: 130px;
    height: auto;
    display: flex;
}

.Contents .div_menu .show_opt_menu{
    flex-direction: column;
}
.Contents .div_menu .show_opt_menu .name_menu{
    width: 100%;
    border-bottom: 1px solid #ffffff;
    padding: 12px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 12px;
}
.Contents .div_menu .show_opt_menu .exit{
    border-bottom: none;
}
.Contents .div_menu .show_opt_menu .name_menu .submenu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}
.Contents .div_menu .show_opt_menu .name_menu .submenu:nth-child(n+2){
    margin-left: 10px;
}
.Contents .div_menu .show_opt_menu .name_menu .submenu .icons{
    width: 12px;
    height: 12px;
}
.Contents .div_menu .show_opt_menu .menu_active{
    width: calc(100% - 12px);
    padding-left: 10px;
    font-weight: bold;
    font-size: 13px;
}
.Contents .div_menu .show_opt_menu .name_menu .menu_active{
    width: calc(100% - 24px);
}

.Contents .div_options{
    width: calc(100% - 260px);
    height: auto;
    padding: 20px;
    overflow-y: auto;
    background: #eef0f5;

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 640px) {
}
.PopUp .show_file {
    width: 760px;
    margin: 0;
}
.PopUp .show_file .show_img{
    max-height: 500px;
    width: auto;
}
.PopUp .show_file .div_video{
    width: -webkit-fill-available;
    height: 400px;
}
@media only screen and (max-width: 800px) {
    .PopUp .show_file{
        width: 80%;
    }
}
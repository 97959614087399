.Page_Administrator_ProductCatalog_Details .textarea{
    resize: none;
}
.Page_Administrator_ProductCatalog_Details .measure{
    width: 120px;
    text-align: center;
}
.Page_Administrator_ProductCatalog_Details .div_data_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #E2E8F0;
    padding: 4px 10px 10px;
    border-radius: 6px;
}
.Page_Administrator_ProductCatalog_Details .data_img_list{
    gap: 30px;
}
.Page_Administrator_ProductCatalog_Details .div_components_img{
    width: calc(100% - 22px);
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_column{
    flex-direction: column;
}
.Page_Administrator_ProductCatalog_Details .div_box_img{
    width: 100%;
}
.Page_Administrator_ProductCatalog_Details .div_box_img:nth-child(n + 1){
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 20px;
}
.Page_Administrator_ProductCatalog_Details .div_box_img:nth-last-child(-n + 1){
    border-bottom: none;
    padding-bottom: 0px;
}
.Page_Administrator_ProductCatalog_Details .div_box_img .list_input_data{
    width: 100% !important;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_row {
    align-items: flex-start;
    justify-content: flex-start;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_row .data_line{
    width: auto;
    padding-bottom: 0;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_row .data_line:nth-child(n + 2){
    border-top: none;
    padding-top: 0;
}
.Page_Administrator_ProductCatalog_Details .div_show_list_img{
    margin: 0 !important;
}
.Page_Administrator_ProductCatalog_Details .data_line:nth-last-child(-n + 1){
    padding-bottom: 0;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_column .list_input_data{
    width: calc(100% - 20px);
}
.Page_Administrator_ProductCatalog_Details .list_input_data .border_bottom{
    margin: 0 !important;
}
.Page_Administrator_ProductCatalog_Details .list_input_data .title_block{
    width: 100%;
}
.Page_Administrator_ProductCatalog_Details .list_input_data .checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    white-space: nowrap;
    gap: 6px;
}
.Page_Administrator_ProductCatalog_Details .list_input_data .checkbox .name_checkbox{
    font-size: 14px;
    line-height: 14px;
}
.Page_Administrator_ProductCatalog_Details .list_input_data .checkbox input{
    width: 18px;
    height: 18px;
    display: flex;
    cursor: pointer;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_column .list_type_data{
    width: 100%;
}
.Page_Administrator_ProductCatalog_Details .flex_direction_column .div_list_img{
    width: calc(100% - 20px);
    border: 1px solid #E2E8F0;
    padding: 8px 10px 10px;
    margin: 0;
    border-radius: 10px;
}
.Page_Administrator_ProductCatalog_Details .inmetro{
    width: 146px;
}

.Page_Administrator_ProductCatalog_Details .div_bloco_img{
    width: calc(100% - 22px);
    border: 1px solid #E2E8F0;
    padding: 10px;
    border-radius: 6px;
    margin-top: 20px;
}
.Page_Administrator_ProductCatalog_Details .separator{
    width: 88px;
    text-align: center;
}
.Page_Administrator_ProductCatalog_Details .flex_grow_space{
    flex-wrap: nowrap;
    align-items: flex-start;
}
.Page_Administrator_ProductCatalog_Details .show_data_line{
    justify-content: flex-start;
    margin-top: 4px !important;
}
.Page_Administrator_ProductCatalog_Details .data_line{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 16px;
}
.Page_Administrator_ProductCatalog_Details .data_line:nth-child(n + 2){
    border-top: 1px solid #E2E8F0;
    padding-top: 16px;
}
.Page_Administrator_ProductCatalog_Details .data_line:nth-last-child(-n + 1){
    padding-bottom: 0px;
}

.Page_Administrator_ProductCatalog_Details .div_select_opt_template{
    justify-content: center;
}
.Page_Administrator_ProductCatalog_Details .div_select_opt_template .opt_template{
    padding: 6px 10px;
    border-radius: 8px;
    color: #ffffff;
    width: 200px;
    cursor: pointer;
    text-align: center;
}
.Page_Administrator_ProductCatalog_Details .div_select_opt_template .yes{
    background-color: #76c57e;
}
.Page_Administrator_ProductCatalog_Details .div_select_opt_template .not{
    background-color: #e95656;
}

.Page_Administrator_ProductCatalog_Details .div_input_code{
    width: 120px;
    text-align: center;
}
.Page_Administrator_ProductCatalog_Details .div_input_3d{
    width: 120px;
}
.Page_Administrator_ProductCatalog_Details .div_code{
    background: #76c57e;
    padding: 10px 14px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
}
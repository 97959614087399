
.Page_Shopkeepers_Profile .div_file{
    display: flex;
    position: relative;
    margin-bottom: 80px;
}
.Page_Shopkeepers_Profile .div_file .show_cover{
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 15px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Page_Shopkeepers_Profile .div_file .not_cover{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    border: 2px dashed #b0b0b0;
    color: #b0b0b0;
}
.Page_Shopkeepers_Profile .div_file .show_cover .alt_cover{
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #20489e;
    color: #ffffff;
    border-radius: 6px;
    padding: 6px 20px;
    cursor: pointer;
}

.Page_Shopkeepers_Profile .div_file .show_img{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50px;
}
.Page_Shopkeepers_Profile .div_file .show_img .file_user{
    display: flex;
}
.Page_Shopkeepers_Profile .div_file .show_img .file_user .icons{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.Page_Shopkeepers_Profile .div_file .show_img .alt_file{
    position: absolute;
    right: -2px;
    cursor: pointer;
}

.Page_Shopkeepers_Profile .cep{
    width: 80px;
    text-align: center;
}
.Page_Shopkeepers_Profile .uf{
    width: 40px;
    text-align: center;
}
.Page_Shopkeepers_Profile .cnpj, .Page_Shopkeepers_Profile .number{
    width: 160px;
    text-align: center;
}

.Page_Shopkeepers_Profile .div_list_img{
    gap: 30px;
}
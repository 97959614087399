.opt_menu{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 16px;
    margin-bottom: 30px;
}
.show_data_user{
    /* ustify-content: center; */
    gap: 10px;
}
.opt_menu .div_data_img{
    width: 40px;
    height: 40px;
    display: flex;
}
.opt_menu .div_data_img .img_user{
    border-radius: 40px;
    display: flex;
}

.opt_menu .div_data_user .hello{
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}
.opt_menu .div_data_user .name_user{
    line-height: 20px;
}
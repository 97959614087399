.div_btn_save {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.div_btn_save .topic_return_admin{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.div_btn_save .topic_return_admin .icons{
    transform: rotate(180deg);   
    width: 16px;
    height: auto; 
}

.div_btn_save .div_new_data{
    position: relative;
    width: auto;
    min-width: 170px;
    text-align: center;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    padding: 0;
    font-size: 14px;
    background-color: #0089D0;
    border-radius: 6px;
    font-family: 'Outfit';
}
.div_btn_save .div_new_data .new_data_add{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    background-color: #20489e;
    border-radius: 6px;
}
.div_btn_save .div_new_data .name_btn_add{
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
}
.div_btn_save .div_new_data:hover .new_data_add{
    width: 100%;
}
.div_btn_save .div_new_data:hover .name_btn_add{
    width: 0;
    padding-left: 0px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations{
    background: #ffffff;
    padding: 16px;
    width: calc(100% - 32px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations:nth-child(1n) {
    margin-top: 20px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_data_user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_data_user .div_img_user{
    width: 46px;
    height: 46px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_data_user .div_img_user .icons{
    width: 100%;
    height: 100%;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_data_user .div_inf{
    color: #7F7F8A;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_data_user .div_inf .show_name{
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_opt_icons{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_opt_icons .div_icons{
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_opt_icons .div_icons .icons_{
    width: 20px;
    height: auto;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_opt_icons .icons_1{
    width: 26px;
    height: 26px;
}
.Page_Administrator_ApprovalRegistrations .show_data_approval_registrations .div_opt_icons .icons_1 .icons{
    width: 26px;
    height: 26px;
}
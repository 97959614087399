.Page_Administrator_ProductTemplate_Details .textarea{
    resize: none;
}
.Page_Administrator_ProductTemplate_Details .measure{
    width: 120px;
    text-align: center;
}
.Page_Administrator_ProductTemplate_Details .div_data_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #E2E8F0;
    padding: 4px 10px 10px;
    border-radius: 6px;
}
.Page_Administrator_ProductTemplate_Details .div_components_img{
    width: calc(100% - 22px);
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.Page_Administrator_ProductTemplate_Details .flex_direction_column{
    flex-direction: column;
}
.Page_Administrator_ProductTemplate_Details .flex_direction_column .list_input_data, 
.Page_Administrator_ProductTemplate_Details .flex_direction_column .list_type_data{
    width: 100%;
}
.Page_Administrator_ProductTemplate_Details .flex_direction_column .div_list_img{
    width: calc(100% - 20px);
    border: 1px solid #E2E8F0;
    padding: 8px 10px 10px;
    margin: 0;
    border-radius: 10px;
}
.Page_Administrator_ProductTemplate_Details .inmetro{
    width: 146px;
}

.Page_Administrator_ProductTemplate_Details .div_bloco_img{
    width: calc(100% - 22px);
    border: 1px solid #E2E8F0;
    padding: 10px;
    border-radius: 6px;
    margin-top: 20px;
}
.Page_Administrator_ProductTemplate_Details .separator{
    width: 88px;
    text-align: center;
}
.Page_Administrator_ProductTemplate_Details .flex_grow_space{
    flex-wrap: nowrap;
    align-items: flex-start;
}
.Page_Administrator_ProductTemplate_Details .show_data_line{
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4px !important;
}
.Page_Administrator_ProductTemplate_Details .data_line{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 16px;
}
.Page_Administrator_ProductTemplate_Details .data_line:nth-child(n + 2){
    border-top: 1px solid #E2E8F0;
    padding-top: 16px;
}
.Page_Administrator_ProductTemplate_Details .data_line:nth-last-child(-n + 1){
    padding-bottom: 0px;
}
.PopUp .edit_or_new_downloads{
    width: 700px;
    margin: 0;
}
.PopUp .edit_or_new_downloads .register_data{
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: none;
}

@media only screen and (max-width: 570px) {
    .PopUp .edit_or_new_downloads {
        width: 90%;
    }
}